import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import { getSettings } from "../lib/Settings";
import Layout from "../components/layouts/Layout";
import Markdown from "../components/Markdown";

const Page = ({ data }) => {
  const { page } = data;

  return (
    <StaticQuery
      query={graphql`
        query {
          settings: allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "settings" } } }
          ) {
            edges {
              node {
                frontmatter {
                  defaultDescription
                  name
                  siteTitle
                  type
                }
              }
            }
          }
        }
      `}
      render={data => {
        const settings = getSettings(data.settings);
        return (
          <Layout>
            <Helmet
              title={`${page.frontmatter.title} - ${settings.site.siteTitle}`}
              meta={
                page.frontmatter.description
                  ? [
                      {
                        name: "description",
                        content: page.frontmatter.description
                      }
                    ]
                  : []
              }
            />
            <Markdown>{page.htmlAst}</Markdown>
          </Layout>
        );
      }}
    />
  );
};

export const pageFragment = graphql`
  fragment pageFragment on MarkdownRemark {
    frontmatter {
      author
      datetime
      description
      path
      status
      title
      type
    }
    html
    htmlAst
  }
`;

export default Page;
