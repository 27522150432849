import React from "react";
import { graphql } from "gatsby";

import Page from "../../templates/Page";

const ProcurementPageOklara = ({ data }) => {
  return <Page data={data} />;
};

export const pageQuery = graphql`
  query {
    page: markdownRemark(
      frontmatter: { path: { eq: "/oklara-utvarderingskriterier" } }
    ) {
      ...pageFragment
    }
  }
`;

export default ProcurementPageOklara;
